
const ONHIDDEN="onHidden";

const SHOW="show";

const CLICKTABLEROW="clickTableRow";

const COMBOGRIDCHANGE="comboGridChange";

const EDITHANDLER="editHandler";

const REFRESHHANDLER="refreshHandler";

const updateKey='update:modelValue';
import {getCurrentInstance, reactive, toRefs,onBeforeMount, onMounted, defineComponent, watch, provide, nextTick} from 'vue';
import ComboGridUtil,{IComboGridDataObj} from "@/components/base_comp/comboGrid/comboGridUtil";

export default defineComponent({
    name: "comboGrid",
    props:{
        modelValue: {
            type: String
        },
        disabled:{type:Boolean,default:false},
        bindEvent:{type:Object,default:()=>{return{}}}
    },
    setup(props,context){
        const {proxy} = getCurrentInstance() as any;
        const utils=proxy.utils;
        provide('gridTableOwner',proxy);
        let comboParam:any=context.attrs.params;
        let dataObj:IComboGridDataObj=reactive<IComboGridDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            visible:false,
            readOnly:false,
            idField:"F_ID",
            txtField:"F_NAME",
            comboText: "",
            searchFieldsArray:[],
            gridParam: {
                showTitle:comboParam.showTitle?comboParam.showTitle:false,
                showToolBar:comboParam.showToolBar?comboParam.showToolBar:false,
                multiple:comboParam.multiple?comboParam.multiple:false,
                canPage: comboParam.canPage?comboParam.canPage:true,
                autoLoad:false,

                queryParam: Object.assign({comboGrid: "comboGrid",searchText: ""}, comboParam),
                pagerParams:{
                    small:true,
                    pagerCount:5,
                    pageSize: 10,
                    layout:'prev, pager, next, jumper'
                },
                modelMethod:comboParam.modelMethod
            },
            comboParam:comboParam,
            otherParams:{
                popoverWidth:400
            }
        });
        onBeforeMount(()=>{
            dataObj.utilInst=new ComboGridUtil(proxy,dataObj,props,context);
        })
        onMounted(async()=>{
            await nextTick(async ()=>{
                await dataObj.utilInst.init(comboParam,props.modelValue);
                if(!comboParam.maxHeight)comboParam.maxHeight=200;
                getTbInst().setMaxTbHeight(comboParam.maxHeight);
                let inputGroup:any=document.querySelector('.el-input-group');
                if(inputGroup && inputGroup.clientWidth && inputGroup.clientWidth>200){
                    dataObj.otherParams.popoverWidth=inputGroup.clientWidth;
                }
            })
        });


        watch(() => props.modelValue,async (newValue,oldValue) => {
            await dataObj.utilInst.comboGridValueChange(newValue,oldValue,props.modelValue,comboParam,context);
            await dataObj.utilInst.emitEvent(COMBOGRIDCHANGE,newValue,oldValue)//触发事件
        })


        watch(() => context.attrs.params,async (newValue,oldValue) => {
            await dataObj.utilInst.init(newValue,props.modelValue);
            dataObj.gridParam.queryParam=Object.assign({comboGrid: "comboGrid",searchText: ""}, comboParam);
        })


        const getTextById=async()=>{
            await dataObj.utilInst.getTextById(props.modelValue,comboParam);
        }

        const inputChange=(value:string)=>{
            if (value == '')context.emit(updateKey,'');
            if(comboParam.content){
                let tempContent=comboParam.content.slice(0);
                comboParam.content.forEach((row:any, index:number)=> {

                    let item=dataObj.searchFieldsArray.find((field:string) => row[field].indexOf(value)>-1);

                    if(!item)tempContent.splice(index,1);
                });
                getTbInst().setTbData(tempContent);
            }else{
                dataObj.gridParam.queryParam.searchText = value;
                getTbInst().queryHandler(true);
            }
        }


        const rowClickHandler=async(row:any)=>{
            await dataObj.utilInst.emitEvent(CLICKTABLEROW,row)
            if (!comboParam.multiple) {
                dataObj.refMap.get('input').$el.click();
            }
        }


        const selectionChange=(selection:any)=>{
            if (selection.length>0){
                let _comboText = "", _comboValue = "";
                selection.forEach((row:any)=>{

                    if(!props.modelValue || props.modelValue.indexOf(row[dataObj.idField])==-1){
                        _comboValue = _comboValue + row[dataObj.idField] + ",";
                        _comboText = _comboText + row[dataObj.txtField]+ ",";
                    }
                });

                if(_comboValue)_comboValue = _comboValue.trim().substr(0, _comboValue.trim().length - 1);
                if(_comboText)_comboText = _comboText.trim().substr(0, _comboText.trim().length - 1);

                if(dataObj.comboText && _comboText){
                    if(comboParam.multiple) dataObj.comboText=dataObj.comboText+','+_comboText;
                    else dataObj.comboText=_comboText;
                }else if(!dataObj.comboText && _comboText)dataObj.comboText = _comboText;

                if(props.modelValue && _comboValue){
                    if(comboParam.multiple) _comboValue=props.modelValue+','+_comboValue;
                    else  _comboValue=_comboValue;
                }else if(props.modelValue && !_comboValue)_comboValue=props.modelValue;
                if(_comboValue!=props.modelValue)context.emit(updateKey,_comboValue);
            }
        }

        const select=(selection:any, row:any)=>{
            let result:boolean=selection.some((item:any)=>item[dataObj.idField]==row[dataObj.idField]);
            if(!result)dataObj.utilInst.dealCancel(row,updateKey);
        }

        const rowClickStatusChange=(row:any,isSelect:boolean)=>{
            if(comboParam.multiple && !isSelect){
                dataObj.utilInst.dealCancel(row,updateKey);
            }
        }

        const selectAll=(selection:any)=>{
            if(selection.length==0){
                dataObj.comboText = "";
                context.emit(updateKey,'');
            }
        }


        const hide=async ()=>{
            let tbData = getTbInst().getTbData();

            if (tbData.length != 0  && dataObj.gridParam.queryParam.searchText) {
                let flag:boolean=false;
                for(let i=0;i<dataObj.searchFieldsArray.length;i++){
                    if(tbData[0][dataObj.searchFieldsArray[i]].indexOf(dataObj.gridParam.queryParam.searchText)>-1){
                        dataObj.comboText = tbData[0][dataObj.txtField];
                        context.emit(updateKey,tbData[0][dataObj.idField]);
                        flag=true;
                        break;
                    }
                }
                if(!flag){
                    dataObj.comboText = '';
                    context.emit(updateKey,'');//触发事件，改变下拉框的值
                }
            } else if (tbData.length == 0) {//影藏面板，表格没有值，不管是否在搜索，下拉框都不应该有值
                dataObj.comboText = '';
                context.emit(updateKey,'');//触发事件，改变下拉框的值
            }
            await dataObj.utilInst.emitEvent(ONHIDDEN,{comboValue:props.modelValue})//触发事件
            dataObj.gridParam.queryParam.searchText = "";//关闭面板，清空搜索框的值
        }
        //下拉面板显示事件
        const show=async()=>{

            if(comboParam.content){
                getTbInst().setTbData(comboParam.content);
            }else{
                if(getTbInst().getTbData().length==0)await getTbInst().queryHandler();

                if(props.modelValue)selectByComboValue();
            }
            await dataObj.utilInst.emitEvent(SHOW)//触发事件
        }

        const selectByComboValue=()=>{
            let tbRef=getTbInst().tbRef;
            let tbData = getTbInst().getTbData();
            tbRef.clearSelection();
            tbData.forEach((row:any)=> {
                if (comboParam.multiple) {
                    if ((props.modelValue as string).indexOf(row[dataObj.idField]) > -1) tbRef.toggleRowSelection(row,true);
                } else {
                    if (row[dataObj.idField] == props.modelValue) tbRef.setCurrentRow(row);
                }
            });
        }

        const setQueryParams=(appendParams:any)=>{
            Object.assign(dataObj.gridParam.queryParam, appendParams);
            getTbInst().queryHandler(true);
            dataObj.comboText = "";
        }


        const inputClear=async ()=>{
            dataObj.comboText = "";
            context.emit(updateKey,'');
            dataObj.gridParam.queryParam.searchText = "";
            await refreshHandler();
        }

        const editHandler=async ()=>{
            await dataObj.utilInst.emitEvent(EDITHANDLER)
        }

        const refreshHandler=async()=>{
            await getTbInst().queryHandler(true);
            let tbData = getTbInst().getTbData();
            await dataObj.utilInst.emitEvent(REFRESHHANDLER,{tbData:tbData})//触发事件
        }

        const getTbInst=()=>{
            return dataObj.refMap.get('gridTable');
        }

        const gridLoaded=(res:any)=>{
            if(props.modelValue){
                let tbRef=getTbInst().tbRef;
                res.rows.forEach((item:any)=>{
                    if((props.modelValue as string).indexOf(item[dataObj.idField])>-1){
                        nextTick(()=>{
                            tbRef.toggleRowSelection(item,true);
                        })
                    }
                })
            }
        }
        return{
            ...toRefs(dataObj),getTextById,inputChange,rowClickHandler,selectionChange,select,rowClickStatusChange,selectAll,
            hide,show,setQueryParams,selectByComboValue,inputClear,editHandler,
            refreshHandler,getTbInst,gridLoaded
        }
    },
    components: {}
});